import React from 'react';
import ReactDOM from 'react-dom/client';
import './cdn/css/main.css';
import App from './App';
import { AuthProvider } from './utils/hooks/useAuth';
import { SocketProvider } from './utils/hooks/useSocket';

const root = ReactDOM.createRoot(document.querySelector('.atna-main-app'));
root.render(
  <React.StrictMode>
        <SocketProvider>
        <AuthProvider>
    <App />
         </AuthProvider>
         </SocketProvider>
  </React.StrictMode>
);

