import React, { useEffect, useState } from 'react';
import { BrowserRouter, Link, Route, Routes } from 'react-router-dom';
import CrmView from './views/crm/page';
import { TabsProvider } from './utils/hooks/useTabs';
import { AlertsProvider, useAlerts } from './utils/hooks/useAlert';
import { fetchCurrentUser } from "./utils/atna"
import Login from './views/auth/login/page';
import { BiLoaderAlt } from 'react-icons/bi';
import Alerts from './views/crm/components/alerts';
import { GlobalStateProvider } from './utils/hooks/useGlobalState';
import { AuthProvider, useAuth } from './utils/hooks/useAuth';
import Home from './views/home/page';
import AdminView from './views/admin/page';
import { useSocket } from './utils/hooks/useSocket';
const App = () => {

  const { user, setUser, loading, setLoading, fetchUser } = useAuth()
  const {clientId, socket} = useSocket()

  useEffect(() => {
    if (socket) {
      console.log('WebSocket connected:', socket.connected); // Vérifie si le socket est connecté
  
      socket.on('forceLogout', (reason) => {
        console.log('Received forceLogout event:', reason);
        // Ici, tu peux gérer la déconnexion, par exemple rediriger vers la page de connexion
        // window.location.href = '/login';
      });
  
      // Nettoyage pour éviter les doubles écouteurs
      return () => {
        socket.off('forceLogout');
      };
    }
  }, [socket]);

  



    
  return (
    <div className='atna-app-inner'>
      <GlobalStateProvider>
      <TabsProvider>
      <BrowserRouter>
     {
     loading ?
     <div className="w-full h-full fixed z-[150] flex justify-center items-center bg-white">
      <BiLoaderAlt className='text-[20px] animate-spin'/>
     </div>
     :
     user.isAuth ?
      <Routes>
         <Route path='/' element={<Home/>}/>
          <Route path='/crm/:numTiers?/:pageView?/:pageState?' element={<CrmView/>}/>
          <Route path='/admin/:pageView?/:pageState?' element={<AdminView/>}/>
      </Routes>
      :
      <Login user={user} setUser={setUser} fetchUser={fetchUser} />  
      }
      </BrowserRouter>
      </TabsProvider>
      </GlobalStateProvider>
    </div>
  );
}

export default App;
