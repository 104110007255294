import React, { createContext, useState, useEffect, useContext, useMemo, useCallback } from 'react';
import { io } from 'socket.io-client';
import { v4 as uuidv4 } from 'uuid';
import config from "../config";

// Créez le contexte
const DataContext = createContext();

// Fournisseur de contexte
const SocketProvider = ({ children }) => {
    const [socket, setSocket] = useState(null);
    const api = config.dev ? config.devApi : config.api;

    // Initialiser ou récupérer le clientId une seule fois
    const clientId = useMemo(() => {
        let storedClientId = localStorage.getItem('clientId');
        if (!storedClientId) {
            storedClientId = uuidv4(); // Générer un nouvel UUID
            localStorage.setItem('clientId', storedClientId);
        }
        return storedClientId;
    }, []);

    // Mémoriser la configuration du socket pour éviter les re-rendus inutiles
    const initializeSocket = useCallback(() => {
        const newSocket = io(api, {
            withCredentials: true,
            transports: ['websocket'],
            query: {
                clientId: clientId
            }
        });

        // Définir les événements du socket
        newSocket.on('disconnect', (reason) => {
            if (reason === 'io server disconnect') {
                console.log('You have been disconnected because of missing clientId. Please reload the page.');
            } else {
                console.log('Disconnected from socket server');
            }
        });

        return newSocket;
    }, [api, clientId]);

    useEffect(() => {
        const newSocket = initializeSocket();
        setSocket(newSocket);

        // Nettoyer lors du démontage
        return () => {
            newSocket.disconnect();
        };
    }, [initializeSocket]);

    // Utiliser `useMemo` pour mémoriser la valeur du contexte
    const value = useMemo(() => ({ socket, clientId }), [socket, clientId]);

    return (
        <DataContext.Provider value={value}>
            {children}
        </DataContext.Provider>
    );
};

// Custom hook pour utiliser le contexte socket
const useSocket = () => {
    const context = useContext(DataContext);
    if (context === undefined) {
        throw new Error("useSocket must be used within a SocketProvider");
    }
    return context;
};

export { SocketProvider, useSocket };
