import React, { useEffect, useRef, useState, Suspense } from 'react';
import { cn } from '../../utils/cn';
import useScreenWidth from '../../utils/hooks/useScreenWidth';
import Sidebar from './components/sidebar';
import Topbar from './components/topbar';
import { AlertsProvider } from '../../utils/hooks/useAlert';
import Alerts from './components/alerts';
import { useParams } from 'react-router-dom';

// Lazy load des composants
const CrmMenuView = React.lazy(() => import('./views/menu/page'));
const CrmSearchView = React.lazy(() => import('./views/recherche/page'));
const CrmTiersView = React.lazy(() => import('./views/tiers/page'));

const CrmView = () => {
  const [sidebarWidth, setSidebarWidth] = useState(248); // Initial width of the sidebar
  const [isResizing, setIsResizing] = useState(false);
  const [cursorTop, setCursorTop] = useState(0);
  const windowWidthSize = useScreenWidth();
  const [navLocked, setNavLocked] = useState(true);
  const topBarNameProps = useRef();
  const [currentTiersRaisonSocial, setCurrentTiersRaisonSocial] = useState(null);
  const { numTiers } = useParams();

  const pageType = !numTiers || (numTiers === "team" || numTiers === "stats") ? "menu" : numTiers === "search" ? "search" : "tiers";

  useEffect(() => {
    if (windowWidthSize > 800) setNavLocked(false);
  }, [windowWidthSize, setNavLocked]);

  return (
    <div className='atna-crm-inner'>
      <div className="flex-[1_1_0%] flex relative flex-row h-full">
        <Sidebar 
          pageType={pageType} 
          sidebarWidth={sidebarWidth} 
          setSidebarWidth={setSidebarWidth} 
          isResizing={isResizing} 
          setIsResizing={setIsResizing} 
          cursorTop={cursorTop} 
          setCursorTop={setCursorTop} 
          windowWidthSize={windowWidthSize} 
          navLocked={navLocked} 
          setNavLocked={setNavLocked} 
        />
        <div 
          className={cn("flex flex-col flex-[1_1_0%] fixed h-full atna-main-content")} 
          style={{
            width: navLocked ? '100vw' : `calc(100vw - ${sidebarWidth}px)`,
            left: navLocked ? '0px' : `${sidebarWidth}px`,
          }}
        >
          <Topbar 
            topBarNameProps={topBarNameProps} 
            navLocked={navLocked} 
            setNavLocked={setNavLocked} 
            pageType={pageType} 
          />

          <main className="atna-view-inner w-full px-[20px] py-[10px]">
            <Suspense fallback={<></>}>
              {pageType === "menu" ? (
                <CrmMenuView />
              ) : pageType === "search" ? (
                <CrmSearchView />
              ) : (
                <AlertsProvider>
                  <Alerts />
                  <CrmTiersView 
                    topBarNameProps={topBarNameProps} 
                    currentTiersRaisonSocial={currentTiersRaisonSocial} 
                    setCurrentTiersRaisonSocial={setCurrentTiersRaisonSocial} 
                  />
                </AlertsProvider>
              )}
            </Suspense>
          </main>
        </div>
      </div>
    </div>
  );
}

export default CrmView;
