import React, { useEffect, useState } from 'react';
import { IoArrowBackOutline, IoArrowForward, IoArrowForwardOutline } from "react-icons/io5";
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { cn } from '../../../utils/cn';
import { useTabs } from '../../../utils/hooks/useTabs';
const Topbar = React.memo(({pageType, navLocked, setNavLocked, topBarNameProps}) => {
    
  const [canGoBack, setCanGoBack] = useState(false);
  const [canGoForward, setCanGoForward] = useState(false);
  const [historyStack, setHistoryStack] = useState([]);
  const navigate = useNavigate()
  const location = useLocation();
  const { numTiers, pageView, pageState } = useParams()
  const [searchParams] = useSearchParams()
  const {currentView} = useTabs(numTiers)


  const limitStr = (str) => {
    if(str && str.length > 50) return `${str.slice(0,50)}...`
    else return str
  }

  useEffect(() => {
    const updateNavigationAvailability = () => {
      setCanGoBack(window.history.state?.idx > 0);
      setCanGoForward(historyStack.length > 0);
    };

    updateNavigationAvailability();

    window.addEventListener('popstate', updateNavigationAvailability);

    return () => {
      window.removeEventListener('popstate', updateNavigationAvailability);
    };
  }, [location, historyStack]);

  const handleGoBack = () => {
    if (canGoBack) {
      setHistoryStack(prevStack => [location.pathname, ...prevStack]);
      navigate(-1);
    }
  };

  const handleGoForward = () => {
    if (canGoForward) {
      const [nextPath, ...remainingStack] = historyStack;
      setHistoryStack(remainingStack);
      navigate(nextPath);
    }
  };


    return (
        <header className="atna-bar flex flex-[1_1_0%] items-center px-[10px] h-topBarHeight bg-black">
        <div className="block shrink-0 grow-0">
                   <div
                   onClick={() => {
                    setNavLocked((prev) => !prev)
                   }}
                    data-title='Afficher' 
                    className={cn("flex atna-tooltip-bottom-right hover:bg-[rgba(0,0,0,0.04)] h-[27px] w-[27px] rounded-[4px] items-center justify-center",
                      navLocked ? "flex" : "hidden"
                    )}>
                     <div role="button" className="w-[11px] h-[11px] rotate-180 grow-0 shrink-0 flex items-center text-[rgba(55,53,47,0.65)]">
                     <svg role="graphics-symbol" viewBox="0 0 16 16"  width={"100%"} height={'100%'} className="doubleChevronLeft"><path d="M7.07031 13.8887C7.2207 14.0391 7.40527 14.1211 7.62402 14.1211C8.06836 14.1211 8.41699 13.7725 8.41699 13.3281C8.41699 13.1094 8.32812 12.9043 8.17773 12.7539L3.37207 8.05762L8.17773 3.375C8.32812 3.21777 8.41699 3.0127 8.41699 2.80078C8.41699 2.35645 8.06836 2.00781 7.62402 2.00781C7.40527 2.00781 7.2207 2.08984 7.07031 2.24023L1.73828 7.44922C1.56055 7.62012 1.46484 7.8252 1.46484 8.06445C1.46484 8.29688 1.55371 8.49512 1.73828 8.67969L7.07031 13.8887ZM13.1748 13.8887C13.3252 14.0391 13.5098 14.1211 13.7354 14.1211C14.1797 14.1211 14.5283 13.7725 14.5283 13.3281C14.5283 13.1094 14.4395 12.9043 14.2891 12.7539L9.4834 8.05762L14.2891 3.375C14.4395 3.21777 14.5283 3.0127 14.5283 2.80078C14.5283 2.35645 14.1797 2.00781 13.7354 2.00781C13.5098 2.00781 13.3252 2.08984 13.1748 2.24023L7.84961 7.44922C7.66504 7.62012 7.57617 7.8252 7.56934 8.06445C7.56934 8.29688 7.66504 8.49512 7.84961 8.67969L13.1748 13.8887Z"></path></svg>
                     </div>
                   </div>
                 </div>
          <div className="flex items-center gap-x-[2px] px-[6px]">
          <div
                   onClick={() => {
                    if(canGoBack) handleGoBack()
                   }}
                    data-title='Revenir' 
                    className={cn("flex atna-tooltip-bottom-right hover:bg-[rgba(0,0,0,0.04)] h-[27px] w-[27px] rounded-[4px] items-center justify-center",
                      !canGoBack && ''
                    )}>
                     <div role="button" className={cn("w-[15px] h-[15px] grow-0 shrink-0 flex items-center text-[rgba(55,53,47,0.30)] pointer-events-none", canGoBack && "text-[rgba(55,53,47,0.65)] ")}>
                      <IoArrowBackOutline/>
                     </div>
                   </div>
                   <div
                   onClick={() => {
                    if(canGoForward) handleGoForward()
                   }}
                    data-title='Avancer' 
                    className={cn("flex atna-tooltip-bottom-right hover:bg-[rgba(0,0,0,0.04)] h-[27px] w-[27px] rounded-[4px] items-center justify-center",
                      !canGoForward && ''
                    )}>
                     <div role="button" className={cn("w-[15px] h-[15px] grow-0 shrink-0 flex items-center text-[rgba(55,53,47,0.30)] pointer-events-none", canGoForward && "text-[rgba(55,53,47,0.65)] ")}>
                      <IoArrowForwardOutline/>
                     </div>
                   </div>
          </div>
          <div className="flex items-center gap-x-[6px] ml-[10px]">


            <div onClick={() => {
              if(pageType === "tiers") navigate('/crm')
              if(pageType === "menu") navigate('/crm')
            }} className="flex bg-[rgba(0,0,0,0.04)] px-[8px] py-[4px] h-[25px] items-center rounded-[6px] select-none cursor-pointer text-[11px] font-medium text-[rgba(0,0,0,0.6)]">
              { pageType === "menu" ? "Menu" : pageType === "search" ? "Rechercher" : "Tiers" }
            </div>


            {(pageType === "tiers" || (pageType === "menu" && numTiers === "team") || (pageType === "search" && searchParams.get('q'))) && 
            <div className="flex bg-[rgba(0,0,0,0.01)] hover:bg-[rgba(0,0,0,0.015)] px-[8px] py-[4px]h-[25px] items-center rounded-[6px] select-none text-[13px] font-black text-[rgba(0,0,0,0.3)]">
              /
            </div>}


            {(pageType === "tiers" || (pageType === "menu" && numTiers === "team") || (pageType === "search" && searchParams.get('q'))) && <div 
            onClick={() => {
              if(pageType === "tiers") navigate(`/crm/${numTiers}`)
            }}
            ref={topBarNameProps}
            className="flex bg-[rgba(0,0,0,0.04)] hover:bg-[rgba(0,0,0,0.04)] px-[8px] py-[4px] h-[25px] items-center rounded-[6px] select-none cursor-pointer text-[11px] font-medium text-[rgba(0,0,0,0.4)] text-nowrap whitespace-nowrap overflow-hidden text-ellipsis">
              {
                  pageType === "search" ? 
                  searchParams.get('q') || ""
                  :
                  pageType === "menu" && numTiers === "team" ?
                  "Mon équipe"
                  :
                  limitStr(currentView?.state?.mainData?.raisonSociale || "...") || "..."
              }
            </div>}
            {(pageType === "tiers" && pageView)  && <div className="flex bg-[rgba(0,0,0,0.01)] hover:bg-[rgba(0,0,0,0.015)] px-[8px] py-[4px] h-[25px] items-center rounded-[6px] select-none text-[11px] font-black text-[rgba(0,0,0,0.3)]">
              /
            </div>}
            {(pageType === "tiers" && pageView) && <div className="flex bg-[rgba(0,0,0,0.02)] hover:bg-[rgba(0,0,0,0.04)] px-[8px] py-[4px] h-[25px] items-center rounded-[6px] select-none cursor-pointer text-[11px] font-medium text-[rgba(0,0,0,0.4)] text-nowrap whitespace-nowrap overflow-hidden text-ellipsis">
              {
                  pageView === "activites" ?
                  "Activités"
                  :
                  pageView === "contacts" ?
                  "Contacts"
                  :
                  pageView === "site" ?
                  "Site Web"
                  :
                  pageView === "map" ?
                  "Map"
                  :
                  pageView === "contrats-concurrents" ?
                  "Contrats Concurrents"
                  :
                  "..." 
              }
            </div>}



            {(pageType === "tiers" && pageView === "contrats-concurrents" && pageState)  && <div className="flex bg-[rgba(0,0,0,0.01)] hover:bg-[rgba(0,0,0,0.015)] px-[8px] py-[4px] h-[25px] items-center rounded-[6px] select-none text-[11px] font-black text-[rgba(0,0,0,0.3)]">
              /
            </div>}
            {(pageType === "tiers" && pageView === "contrats-concurrents" && pageState) && <div className="flex bg-[rgba(0,0,0,0.02)] hover:bg-[rgba(0,0,0,0.04)] px-[8px] py-[4px] h-[25px] items-center rounded-[6px] select-none cursor-pointer text-[11px] font-medium text-[rgba(0,0,0,0.4)] text-nowrap whitespace-nowrap overflow-hidden text-ellipsis">
              Contrat
            </div>}
          </div>
        </header>
    );
})

export default Topbar;
