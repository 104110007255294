import React from 'react';
import { HiOutlineArrowRight } from 'react-icons/hi';
import { Link, useNavigate } from 'react-router-dom';
import { useGlobalState } from '../../utils/hooks/useGlobalState';
import { useAuth } from '../../utils/hooks/useAuth';
import { authLogout } from '../../utils/atna';
const Home = () => {

    const navigate = useNavigate()
    const { user, setUser } = useAuth()
   
    async function handleLogout() {
        const userRes = await authLogout();
        setUser({ isAuth: false });
        navigate("/")
      }


    return (
        <div className='w-full h-full flex items-center justify-center relative'>

            <div className="absolute left-12 top-12">
                <img src={require('../../cdn/images/atna-icone-noir.png')} alt="aTna" className='h-8' />
            </div>
   

            <div className="flex flex-col gap-y-4 w-max px-8 max-sm:items-center">
                <h1 className='text-lg  text-grey font-sans font-bold'>Connecté en tant que <span>{user.prenom || '...'}</span></h1>

                <div className="flex gap-x-8 gap-y-4 flex-wrap items-center max-smjustify-center">

                    <Link to="/crm" className="group flex flex-col justify-center h-32 w-56 rounded-xl px-6 py-4 ring-1 ring-grey/5 hover:ring-grey hover:ring-2 cursor-pointer transition-all duration-75 relative">
                        <HiOutlineArrowRight className='absolute right-4 top-6 -translate-x-4 opacity-0 group-hover:-translate-x-0 group-hover:opacity-100 transition-all duration-200'/>
                        <h1 className='text-lg  text-grey font-sans font-bold'>Crm</h1>
                        <h2 className='text-xs text-grey font-display font-normal'>Gérer les tiers et l'activité.</h2>
                    </Link>

                 {   
                 user.estAdmin === 1 && <Link to="/admin" className="group flex flex-col justify-center h-32 w-56 rounded-xl px-6 py-4 ring-1 ring-grey/5 hover:ring-grey hover:ring-2 cursor-pointer transition-all duration-75 relative">
                        <HiOutlineArrowRight className='absolute right-4 top-6 -translate-x-4 opacity-0 group-hover:-translate-x-0 group-hover:opacity-100 transition-all duration-200'/>
                        <h1 className='text-lg  text-grey font-sans font-bold'>Admin.</h1>
                        <h2 className='text-sm text-grey font-display font-normal'>Accéder aux menus de configuration</h2>
                    </Link>
                }

                </div>

                <div 
                onClick={() => handleLogout()}
                className='bg-primary text-grey ring-2 ring-primary/50 px-[20px] py-[6px] rounded-[10px] w-max text-[12px] font-medium mt-1 cursor-pointer'>
                    Se déconnecter
                </div>

                
            </div>
            
      

        </div>
    );
}

export default Home;