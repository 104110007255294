// DataContext.js
import React, { createContext, useState, useEffect, useContext, useCallback, useMemo } from 'react';
import { 
    getActiviteActions, 
    getActivitePriorites, 
    getDataCodesNAF, 
    getDataCommunes, 
    getDataSecteurs, 
    getPersonnel 
} from '../atna';

// Créez le contexte
const DataContext = createContext();

// Fournisseur de contexte
const GlobalStateProvider = ({ children }) => {
    const [listCodeNaf, setListCodeNaf] = useState([]);
    const [listCommunes, setListCommunes] = useState([]);
    const [listSecteurs, setListSecteurs] = useState([]);
    const [listPersonnel, setListPersonnel] = useState([]);
    const [activiteActions, setActiviteActions] = useState([]);
    const [activitePriorites, setActivitePriorites] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const listStructure = useMemo(() => [
        { value: "Repro-IT", label: "Repro-IT" },
        { value: "Data-IT", label: "Data-IT" },
        { value: "Phone-IT", label: "Phone-IT" },
        { value: "Ident-IT", label: "Ident-IT" },
    ], []);

    const fetchData = useCallback(async () => {
        setLoading(true);
        setError(null);

        try {
            const [
                codesNAF, 
                communes, 
                secteurs, 
                personnel, 
                activiteActions, 
                activitePriorites
            ] = await Promise.all([
                getDataCodesNAF(),
                getDataCommunes(),
                getDataSecteurs(),
                getPersonnel(),
                getActiviteActions(),
                getActivitePriorites()
            ]);

            setListCodeNaf(codesNAF);
            setListCommunes(communes);
            setListSecteurs(secteurs);
            setListPersonnel(personnel);
            setActiviteActions(activiteActions);
            setActivitePriorites(activitePriorites);
        } catch (error) {
            console.error("Error fetching data:", error);
            setError(error);
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const value = useMemo(() => ({
        listCodeNaf, 
        listCommunes, 
        listSecteurs, 
        listPersonnel, 
        activiteActions, 
        activitePriorites, 
        listStructure, 
        fetchData, 
        loading, 
        error
    }), [
        listCodeNaf, 
        listCommunes, 
        listSecteurs, 
        listPersonnel, 
        activiteActions, 
        activitePriorites, 
        listStructure, 
        fetchData, 
        loading, 
        error
    ]);

    return (
        <DataContext.Provider value={value}>
            {children}
        </DataContext.Provider>
    );
};

// Custom hook for using the data context
const useGlobalState = () => {
    const context = useContext(DataContext);
    if (context === undefined) {
        throw new Error("useGlobalState must be used within a GlobalStateProvider");
    }
    return context;
};

export { GlobalStateProvider, useGlobalState };
