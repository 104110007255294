import React, { useEffect, useState, useCallback } from 'react';
import { cn } from '../../../utils/cn';
import { IoBookmarkOutline, IoClose, IoGlobeOutline, IoList, IoSearch, IoShareSocial } from "react-icons/io5";
import { IoMdPin } from "react-icons/io";
import { FiInfo } from "react-icons/fi";
import { HiOutlineViewGrid } from "react-icons/hi";
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useTabs } from '../../../utils/hooks/useTabs';
import { RiMapPin2Line } from "react-icons/ri";
import { useAuth } from '../../../utils/hooks/useAuth';

const Sidebar = React.memo(({ windowWidthSize, isResizing, setCursorTop, cursorTop, sidebarWidth, setSidebarWidth, navLocked, setNavLocked, setIsResizing, pageType }) => {
  const { numTiers, pageView } = useParams();
  const [dropVisible, setDropVisible] = useState(false);
  const [userDropVisible, setUserDropVisible] = useState(false);
  const { getViews, deleteView } = useTabs();
  const views = getViews();
  const { user, userPhoto, handleLogout } = useAuth();
  const navigate = useNavigate();

  const handleMouseDown = useCallback((e) => {
    setIsResizing(true);
  }, [setIsResizing]);

  useEffect(() => {
    const handleMouseMove = (e) => {
      const newHeight = e.clientY - 10;
      setCursorTop(newHeight);
      if (!isResizing) return;
      document.querySelector('.atna-sidebar')?.classList.add('atna-sidebar-resize-hover');
      document.querySelector('.sidebar-resize-message')?.classList.remove('showed');
      const newWidth = e.clientX;
      if (newWidth >= 248 && newWidth <= 400) {
        setSidebarWidth(newWidth);
      }
    };

    const handleMouseUp = () => {
      if (isResizing) {
        if (!document.querySelector('.hover-bar-detection:hover')) document.querySelector('.atna-sidebar')?.classList.remove('atna-sidebar-resize-hover');
        document.body.style.cursor = 'default';
      }
      setIsResizing(false);
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);

    const handleClickIsOutDrop = (e) => {
      if (!e.target?.closest('.atna-drop-menu') && !e.target?.closest('.atna-drop-inner')) setDropVisible(() => false);
      if (!e.target?.closest('.atna-user-menu')) setUserDropVisible(() => false);
      e.stopPropagation();
    };

    document.addEventListener('click', handleClickIsOutDrop);
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
      document.removeEventListener('click', handleClickIsOutDrop);
    };
  }, [isResizing, setCursorTop, setIsResizing, setSidebarWidth]);

  const handleSearchNavigation = useCallback(() => {
    navigate("/crm/search?q=");
    if (windowWidthSize < 800) setNavLocked(() => true);
    setTimeout(() => {
      // Any state updates or heavy operations can be triggered here after navigation
    }, 0);
  }, [navigate, windowWidthSize, setNavLocked]);

  return (
    <nav className={cn("atna-sidebar select-none", navLocked && "atna-sidebar-locked min-w-0 w-0")} style={{ width: sidebarWidth, transform: `translateX(-${navLocked ? sidebarWidth + 30 : 0}px)` }}>
      <div className="flex flex-col h-full">
        <div className="block shrink-0 grow-0 cursor-pointer pointer-events-auto">
          <div className="flex items-center justify-between px-[16px] py-[10px] mb-[10px]">
            <Link to="/" role='button' tabIndex={0} data-title="Accéder au menu" className=" h-[25px] w-[25px] shrink-0 grow-0 hover:bg-[rgba(0,0,0,0.04)] rounded-[4px] flex items-center justify-center">
              <img src={require('../../../cdn/images/atna-icone-noir.png')} alt="aTna" className='w-[20px] h-[20px]' />
            </Link>
            <div
              onClick={() => {
                setNavLocked((prev) => !prev);
              }}
              role="button" tabIndex={0}
              data-title='Fermer' className="flex hover:bg-[rgba(0,0,0,0.04)] h-[25px] w-[25px] rounded-[4px] items-center justify-center">
              <div className="w-[10px] h-[10px] grow-0 shrink-0 flex items-center text-[rgba(55,53,47,0.45)]">
                <svg role="graphics-symbol" viewBox="0 0 16 16" width={"100%"} height={'100%'} className="doubleChevronLeft"><path d="M7.07031 13.8887C7.2207 14.0391 7.40527 14.1211 7.62402 14.1211C8.06836 14.1211 8.41699 13.7725 8.41699 13.3281C8.41699 13.1094 8.32812 12.9043 8.17773 12.7539L3.37207 8.05762L8.17773 3.375C8.32812 3.21777 8.41699 3.0127 8.41699 2.80078C8.41699 2.35645 8.06836 2.00781 7.62402 2.00781C7.40527 2.00781 7.2207 2.08984 7.07031 2.24023L1.73828 7.44922C1.56055 7.62012 1.46484 7.8252 1.46484 8.06445C1.46484 8.29688 1.55371 8.49512 1.73828 8.67969L7.07031 13.8887ZM13.1748 13.8887C13.3252 14.0391 13.5098 14.1211 13.7354 14.1211C14.1797 14.1211 14.5283 13.7725 14.5283 13.3281C14.5283 13.1094 14.4395 12.9043 14.2891 12.7539L9.4834 8.05762L14.2891 3.375C14.4395 3.21777 14.5283 3.0127 14.5283 2.80078C14.5283 2.35645 14.1797 2.00781 13.7354 2.00781C13.5098 2.00781 13.3252 2.08984 13.1748 2.24023L7.84961 7.44922C7.66504 7.62012 7.57617 7.8252 7.56934 8.06445C7.56934 8.29688 7.66504 8.49512 7.84961 8.67969L13.1748 13.8887Z"></path></svg>
              </div>
            </div>
          </div>
        </div>
        <div className="block shrink-0 grow-0 cursor-pointer pointer-events-auto ">
          <div className="relative atna-drop-inner">
            <div
              onClick={() => {
                setDropVisible((prev) => !prev);
              }}
              role="button" tabIndex={0} className={cn("flex items-center mx-[8px] my-[6px] hover:bg-[rgba(0,0,0,0.04)] active:bg-[rgba(0,0,0,0.1)] rounded-[5px] justify-between")}>
              <div className="flex flex-[1_1_auto] items-center h-[25px] gap-x-[8px] px-[8px]">
                <div role="button" tabIndex={0} className="flex-[1_1_auto] flex items-center justify-between select-none rounded-[5px] pr-[8px]">
                  <h1 className="text-[12px] px-[5px] text-[rgba(55,53,47,0.5)] font-medium">Vues récentes</h1>
                  <div className="ml-[4px] -mb-[3px] w-[8px] h-[8px] grow-0 shrink-0 flex items-center text-[rgba(55,53,47,0.5)]">
                    <svg role="graphics-symbol" viewBox="0 0 15 9" width={"100%"} height={'100%'} className="chevronDownRounded"><path d="M7.92188 8.65625C8.19531 8.64844 8.44531 8.54688 8.64844 8.32812L14.5859 2.25C14.7578 2.07812 14.8516 1.85938 14.8516 1.60156C14.8516 1.08594 14.4453 0.671875 13.9297 0.671875C13.6797 0.671875 13.4375 0.773438 13.2578 0.953125L7.92969 6.42969L2.58594 0.953125C2.40625 0.78125 2.17188 0.671875 1.91406 0.671875C1.39844 0.671875 0.992188 1.08594 0.992188 1.60156C0.992188 1.85938 1.08594 2.07812 1.25781 2.25L7.20312 8.32812C7.41406 8.54688 7.64844 8.65625 7.92188 8.65625Z"></path></svg>
                  </div>
                </div>
              </div>
            </div>
            <div className={cn("atna-drop-menu  absolute top-[calc(100%_+_5px)] left-[8px] w-[320px] h-[120px] py-[5px] opacity-0 pointer-events-none transition-all duration-75 flex",
              dropVisible && "opacity-100 pointer-events-auto"
            )}>
              <div className="flex-[1_1_0%]  px-[4px] flex flex-col">
                <div className="flex flex-col items-center flex-[1_1_0%] overflow-x-hidden overflow-y-auto atna-drop-menu-scroll">
                  {views?.length > 0 ?
                    views.map((view, viewIndex) => (
                      <div key={viewIndex} role="button" tabIndex={0}
                        onClick={() => {
                          navigate(`/crm/${view.id}`);
                          setDropVisible(() => false);
                        }}
                        className={cn("group h-[25px] max-h-[25px] grow-0 shrink-0 px-[10px] mt-[4px] rounded-[6px] hover:bg-[rgba(0,0,0,0.03)] flex w-full items-center justify-between", numTiers === view.id && "bg-[rgba(0,0,0,0.03)]")}>
                        <div key={"views" + viewIndex} className="flex items-center gap-x-[8px] grow-0 shrink-0  px-[5px]">
                          <h1 className='text-[12px] font-medium text-[rgba(55,53,47,0.9)]'>{view.id === "nouveau" ? "Nouveau tiers" : view.state?.mainData?.raisonSociale}</h1>
                        </div>
                        <div role='button'
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            if (numTiers === view.id) navigate('/crm');
                            deleteView(view.id);
                          }}
                          className="w-[15px] h-[15px] cursor-pointer rounded-full flex items-center justify-center text-[rgb(145,145,142)] text-[13px] font-medium opacity-0 transition-all duration-75 group-hover:opacity-100">
                          <IoClose className='text-[12px]' />
                        </div>
                      </div>
                    ))
                    :
                    <h1 className="text-[12px] px-[12px] text-[rgb(145,145,142)] font-medium h-full flex items-center">Aucune vue ouverte</h1>
                  }
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col grow-0 shrink-0 gap-y-[2px] mx-[8px] mt-[15px]">
            <Link
              to="/crm"
              role='button' tabIndex={0} onClick={() => {
                if (windowWidthSize < 800) setNavLocked(() => true);
              }} data-title="Revenir au menu" className={cn("flex items-center gap-x-[12px] px-[8px] h-[25px]  hover:bg-[rgba(0,0,0,0.04)] active:bg-[rgba(0,0,0,0.1)] rounded-[5px]", pageType === "menu" && "bg-[rgba(0,0,0,0.04)]")}>
              <div className='text-[13px] text-[rgb(95,94,91)]'>
                <HiOutlineViewGrid />
              </div>
              <div className='text-[12px] font-medium text-[rgb(95,94,91)] leading-[1.4] flex-[1_1_auto] whitespace-nowrap overflow-hidden text-ellipsis'>
                Menu
              </div>
            </Link>
            <Link
              to="/crm/search?q="
              role='button' tabIndex={0} onClick={handleSearchNavigation} data-title="Rechercher un tiers" className={cn("flex items-center gap-x-[12px] px-[8px] h-[25px]  hover:bg-[rgba(0,0,0,0.04)] active:bg-[rgba(0,0,0,0.1)] rounded-[5px]", pageType === "search" && "bg-[rgba(0,0,0,0.04)]")}>
              <div className='text-[13px] text-[rgb(95,94,91)]'>
                <IoSearch />
              </div>
              <div className='text-[12px] font-medium text-[rgb(95,94,91)] leading-[1.4] flex-[1_1_auto] whitespace-nowrap overflow-hidden text-ellipsis'>
                Rechercher
              </div>
            </Link>
            {
              pageType === "tiers" &&
              <div className="flex flex-col grow-0 shrink-0 gap-y-[2px] ">
                <div className="h-[30px] flex items-center px-[8px]">
                  <h1 className="text-[12px] text-[rgb(145,145,142)] font-medium">Tiers</h1>
                </div>
                <Link
                  key={"sideBarTiersInfos"}
                  to={`/crm/${numTiers}`}
                  role='button' tabIndex={0} onClick={() => {
                    if (windowWidthSize < 800) setNavLocked(() => true);
                  }}
                  data-title="Informations" className={cn("flex items-center gap-x-[12px] px-[8px] h-[25px]  hover:bg-[rgba(0,0,0,0.04)] active:bg-[rgba(0,0,0,0.1)] rounded-[5px]", pageType === "tiers" && !pageView && "bg-[rgba(0,0,0,0.04)]")}>
                  <div className='text-[13px] text-[rgb(95,94,91)]'>
                    <FiInfo />
                  </div>
                  <div className='text-[12px] font-medium text-[rgb(95,94,91)] leading-[1.4] flex-[1_1_auto] whitespace-nowrap overflow-hidden text-ellipsis'>
                    Informations
                  </div>
                </Link>

                {numTiers !== "nouveau" &&
                  <div className="flex flex-col grow-0 shrink-0 gap-y-[2px]">
                    <Link
                      key={"sideBarTiersAct"}
                      to={`/crm/${numTiers}/activites`}
                      role='button' tabIndex={0} onClick={() => {
                        if (windowWidthSize < 800) setNavLocked(() => true);
                      }}
                      data-title="Activités" className={cn("flex items-center gap-x-[12px] px-[8px] h-[25px]  hover:bg-[rgba(0,0,0,0.04)] active:bg-[rgba(0,0,0,0.1)] rounded-[5px]", pageType === "tiers" && pageView === "activites" && "bg-[rgba(0,0,0,0.04)]")}>
                      <div className='text-[13px] text-[rgb(95,94,91)]'>
                        <IoBookmarkOutline />
                      </div>
                      <div className='text-[12px] font-medium text-[rgb(95,94,91)] leading-[1.4] flex-[1_1_auto] whitespace-nowrap overflow-hidden text-ellipsis'>
                        Activités
                      </div>
                    </Link>
                    <Link
                      key={"sideBarTiersContacts"}
                      to={`/crm/${numTiers}/contacts`}
                      role='button' tabIndex={0} onClick={() => {
                        if (windowWidthSize < 800) setNavLocked(() => true);
                      }}
                      data-title="Contacts" className={cn("flex items-center gap-x-[12px] px-[8px] h-[25px]  hover:bg-[rgba(0,0,0,0.04)] active:bg-[rgba(0,0,0,0.1)] rounded-[5px]", pageType === "tiers" && pageView === "contacts" && "bg-[rgba(0,0,0,0.04)]")}>
                      <div className='text-[13px] text-[rgb(95,94,91)]'>
                        <IoShareSocial />
                      </div>
                      <div className='text-[12px] font-medium text-[rgb(95,94,91)] leading-[1.4] flex-[1_1_auto] whitespace-nowrap overflow-hidden text-ellipsis'>
                        Contacts
                      </div>
                    </Link>
                    <Link
                      key={"sideBarTierSiteWeb"}
                      to={`/crm/${numTiers}/site`}
                      role='button' tabIndex={0} onClick={() => {
                        if (windowWidthSize < 800) setNavLocked(() => true);
                      }}
                      data-title="Site Web" className={cn("flex items-center gap-x-[12px] px-[8px] h-[25px]  hover:bg-[rgba(0,0,0,0.04)] active:bg-[rgba(0,0,0,0.1)] rounded-[5px]", pageType === "tiers" && pageView === "site" && "bg-[rgba(0,0,0,0.04)]")}>
                      <div className='text-[13px] text-[rgb(95,94,91)]'>
                        <IoGlobeOutline />
                      </div>
                      <div className='text-[12px] font-medium text-[rgb(95,94,91)] leading-[1.4] flex-[1_1_auto] whitespace-nowrap overflow-hidden text-ellipsis'>
                        Site Web
                      </div>
                    </Link>
                    <Link
                      key={"sideBarTierMaps"}
                      to={`/crm/${numTiers}/map`}
                      role='button' tabIndex={0} onClick={() => {
                        if (windowWidthSize < 800) setNavLocked(() => true);
                      }}
                      data-title="Map" className={cn("flex items-center gap-x-[12px] px-[8px] h-[25px]  hover:bg-[rgba(0,0,0,0.04)] active:bg-[rgba(0,0,0,0.1)] rounded-[5px]", pageType === "tiers" && pageView === "map" && "bg-[rgba(0,0,0,0.04)]")}>
                      <div className='text-[13px] text-[rgb(95,94,91)]'>
                        <RiMapPin2Line />
                      </div>
                      <div className='text-[12px] font-medium text-[rgb(95,94,91)] leading-[1.4] flex-[1_1_auto] whitespace-nowrap overflow-hidden text-ellipsis'>
                        Map
                      </div>
                    </Link>
                    <Link
                      key={"sideBarTierContratsConcurrents"}
                      to={`/crm/${numTiers}/contrats-concurrents`}
                      role='button' tabIndex={0} onClick={() => {
                        if (windowWidthSize < 800) setNavLocked(() => true);
                      }}
                      data-title="Contrats concurrents" className={cn("flex items-center gap-x-[12px] px-[8px] h-[25px]  hover:bg-[rgba(0,0,0,0.04)] active:bg-[rgba(0,0,0,0.1)] rounded-[5px]", pageType === "tiers" && pageView === "contrats-concurrents" && "bg-[rgba(0,0,0,0.04)]")}>
                      <div className='text-[13px] text-[rgb(95,94,91)]'>
                        <IoList />
                      </div>
                      <div className='text-[12px] font-medium text-[rgb(95,94,91)] leading-[1.4] flex-[1_1_auto] whitespace-nowrap overflow-hidden text-ellipsis'>
                        Contrats concurrents
                      </div>
                    </Link>
                  </div>
                }
              </div>
            }
          </div>
        </div>

        <div className="flex flex-1 items-end py-[10px] max-sm:py-[20px] px-[10px] pointer-events-auto">
          <div className="relative w-full group atna-user-menu">
            <div
              onClick={() => {
                setUserDropVisible((prev) => !prev);
              }}
              className="h-[50px] w-full rounded-[10px] max-sm:rounded-[20px] bg-black/[0.04] active:bg-black/[0.06] px-[10px] py-[0px] max-sm:h-[60px] flex items-center gap-x-[15px] cursor-pointer">
              <div className="w-[35px] h-[35px] rounded-full bg-black/[0.04] overflow-hidden">
                <img src={userPhoto} alt="" className='w-full h-full' />
              </div>
              <h1 className='text-[13px] font-medium max-w-[200px] text-nowrap whitespace-nowrap overflow-hidden text-ellipsis'>{user.prenom} {user.nom}</h1>
            </div>

            <div className={cn("absolute w-full max-[200px] bg-white atna-drop-menu box-border bottom-[calc(100%_+_10px)] px-[5px] py-[5px] opacity-0 pointer-events-none transition-all duration-75",
              userDropVisible && "opacity-100 pointer-events-auto"
            )}>
              <div className="flex flex-col gap-y-[2px] ">
                <div key={"logOutButton"} role="button" tabIndex={0}
                  onClick={handleLogout}
                  className={cn("group h-[25px] max-h-[25px] grow-0 shrink-0 px-[10px] rounded-[6px] hover:bg-[rgba(0,0,0,0.03)] flex w-full items-center justify-between")}>
                  <div key={"btn_logOut"} className="flex items-center gap-x-[8px] grow-0 shrink-0 px-[5px]">
                    <h1 className='text-[12px] font-medium text-[rgba(55,53,47,0.9)]'>Se déconnecter</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="atna-tooltip sidebar-resize-message max-lg:hidden" style={{ top: cursorTop + "px" }}>
        Glisser <span>pour redimensionner</span> <br></br>
      </div>
      <div className='absolute top-0 right-0 grow-0 w-0 h-full z-1 pointer-events-auto'>
        <div className='h-full w-[12px] cursor-col-resize -ml-[6px] hover-bar-detection'
          onMouseEnter={(e) => {
            document.querySelector('.atna-sidebar')?.classList.add('atna-sidebar-resize-hover');
            document.querySelector('.sidebar-resize-message')?.classList.add('showed');
          }}
          onMouseLeave={(e) => {
            if (!isResizing) document.querySelector('.atna-sidebar')?.classList.remove('atna-sidebar-resize-hover');
            document.querySelector('.sidebar-resize-message')?.classList.remove('showed');
          }}
          onMouseDown={handleMouseDown}
        >
        </div>
      </div>
    </nav>
  );
});

export default Sidebar;
