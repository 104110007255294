import { useReducer, useCallback, useContext, createContext } from 'react';

// Définir l'état initial
const initialState = {
  views: []
};

// Réducteur pour gérer les actions sur les vues
const tabsReducer = (state, action) => {
  switch (action.type) {
    case 'ADD_VIEW':
      // Si la vue existe déjà, ne rien faire
      if (state.views.find(view => view.id === action.payload.id)) {
        return state;
      }
      return {
        ...state,
        views: [...state.views, action.payload]
      };
    case 'SET_VIEW':
      return {
        ...state,
        views: state.views.map(view =>
          view.id === action.payload.id ? { ...view, ...action.payload } : view
        )
      };
    case 'DELETE_VIEW':
      return {
        ...state,
        views: state.views.filter(view => view.id !== action.payload.id)
      };
    default:
      return state;
  }
};

// Créer le contexte pour partager l'état global des vues
const TabsContext = createContext();

export const TabsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(tabsReducer, initialState);

  return (
    <TabsContext.Provider value={{ state, dispatch }}>
      {children}
    </TabsContext.Provider>
  );
};

// Hook personnalisé pour gérer les vues
export const useTabs = (viewId) => {
  const { state, dispatch } = useContext(TabsContext);

  // Vérifier si une vue existe
  const viewExists = useCallback((id) => {
    return state.views.some(view => view.id === id);
  }, [state.views]);

  // Ajouter une nouvelle vue
  const addView = useCallback((type, id, state = {}) => {
    if (!viewExists(id)) {
      dispatch({
        type: 'ADD_VIEW',
        payload: { type, id, state }
      });
    }
  }, [dispatch, viewExists]);

  // Modifier une vue existante
  const setView = useCallback((id, state) => {
    if (viewExists(id)) {
      dispatch({
        type: 'SET_VIEW',
        payload: { id, state }
      });
    }
  }, [dispatch, viewExists]);

  // Supprimer une vue
  const deleteView = useCallback((id) => {
    if (viewExists(id)) {
      dispatch({
        type: 'DELETE_VIEW',
        payload: { id }
      });
    }
  }, [dispatch, viewExists]);

  // Récupérer les informations de la vue actuelle
  const currentView = state.views.find(view => view.id === viewId);

  const getViews = useCallback(() => {
    return state.views;
  }, [state.views]);

  return {
    views: state.views,
    currentView,
    addView,
    setView,
    deleteView,
    getViews
  };
};
