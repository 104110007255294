import axios from "axios"
import config from "./config"
const apiBaseUrl = config.dev ? config.devApi : config.api 

// Affichage d'un tiers
export const fetchTiers = async({id}) => {
    try {
        const resApi = await axios(`${apiBaseUrl}/api/tiers/${id}`, {
            method: "GET",
            withCredentials: true,
        }).catch((error) => {
            console.error(`[API ERROR] : Fetching tiers ${id} ${error}`);
            return null;
        })
     //   console.log(resApi)
        if (!resApi || resApi.status !== 200) {
          console.error(`[API ERROR] : Fetching tiers ${id} ${resApi.data}`);
          return null
        }
        const data = await resApi.data
        if(data.status === 400) return null
        if(data.status === 404) return null
        if(data.status !== 200) return null
        return data.data
      } catch (error) {
        console.error(`[API ERROR] : Fetching tiers ${id} ${error}`);
        return null;
      }
}

export const editTiers = async({id, data = {}}) => {
  try {
    const resApi = await axios(`${apiBaseUrl}/api/tiers/${id}/edit`, {
        method: "POST",
        withCredentials: true,
        data
    }).catch((error) => {
        console.error(`[API ERROR] : Editing tiers ${id} ${error}`);
        return {status: 400, data: "Erreur lors de la modification du tiers."};
    })
    if (!resApi || resApi.status !== 200) {
      return {status: 400, data: "Erreur lors de la communication vers l'api."};
    }
    const resData = await resApi.data
    return resData
  } catch (error) {
    return {status: 400, data: "Erreur lors de la communication vers l'api."};
  }
}

export const deleteTiers = async({id}) => {
  try {
    const resApi = await axios(`${apiBaseUrl}/api/tiers/${id}/delete`, {
        method: "POST",
        withCredentials: true,
    }).catch((error) => {
        console.error(`[API ERROR] : Editing tiers ${id} ${error}`);
        return {status: 400, data: "Erreur lors de la suppression du tiers."};
    })
    if (!resApi || resApi.status !== 200) {
      return {status: 400, data: "Erreur lors de la communication vers l'api."};
    }
    const resData = await resApi.data
    return resData
  } catch (error) {
    return {status: 400, data: "Erreur lors de la communication vers l'api."};
  }
}

export const mergeTiers = async({id,importerId}) => {
  try {
    const resApi = await axios(`${apiBaseUrl}/api/tiers/${id}/merge/${importerId}`, {
        method: "POST",
        withCredentials: true,
    }).catch((error) => {
        console.error(`[API ERROR] : Editing tiers ${id} ${error}`);
        return {status: 400, data: "Erreur lors de la fusion des tiers."};
    })
    if (!resApi || resApi.status !== 200) {
      return {status: 400, data: "Erreur lors de la communication vers l'api."};
    }
    const resData = await resApi.data
    return resData
  } catch (error) {
    return {status: 400, data: "Erreur lors de la communication vers l'api."};
  }
}

export const createTiers = async({data = {}}) => {
  try {
    const resApi = await axios(`${apiBaseUrl}/api/tiers/create`, {
        method: "POST",
        withCredentials: true,
        data
    }).catch((error) => {
        console.error(`[API ERROR] : Creating tiers ${error}`);
        return {status: 400, data: "Erreur lors de la création du tiers."};
    })
    if (!resApi || resApi.status !== 200) {
      return {status: 400, data: "Erreur lors de la communication vers l'api."};
    }
    const resData = await resApi.data
    return resData
  } catch (error) {
    return {status: 400, data: "Erreur lors de la communication vers l'api."};
  }
}

export const searchTiers = async({query, secteur, cellule, codeNAF, cp, id_ic, idTiers, limit=50, page=1 }) => {
  try {
      const resApi = await axios(`${apiBaseUrl}/api/tiers/search`, {
          method: "POST",
          withCredentials: true,
          data: {
            query,
            secteur, 
            cellule, 
            cp,
            codeNAF,
            id_ic,
            idTiers,
            limit,
            page
          }
      }).catch((error) => {
          console.error(`[API ERROR] : Searching tiers ${query} ${error}`);
          return null;
      })
      if (!resApi || resApi.status !== 200) {
        console.error(`[API ERROR] : Fetching tiers ${query} ${resApi.data}`);
        return null
      }
      const data = await resApi.data
      if(data.status === 400) return null
      if(data.status === 404) return null
      if(data.status !== 200) return null
      return data.data
    } catch (error) {
      console.error(`[API ERROR] : Fetching tiers ${query} ${error}`);
      return null;
    }
}

// Activites
export const fetchTiersActivites = async({id}) => {
  try {
    const resApi = await axios(`${apiBaseUrl}/api/tiers/activites/${id}`, {
        method: "GET",
        withCredentials: true,
    }).catch((error) => {
        console.error(`[API ERROR] : Fetching tiers ${id} activities ${error}`);
        return {status: 400, data: "Erreur lors de la récupération des activités du tiers."};
    })
    if (!resApi || resApi.status !== 200) {
      return {status: 400, data: "Erreur lors de la communication vers l'api."};
    }
    const resData = await resApi.data
    return resData
  } catch (error) {
    return {status: 400, data: "Erreur lors de la communication vers l'api."};
  }
}

export const fetchTiersActivitesLength = async({id}) => {
  try {
    const resApi = await axios(`${apiBaseUrl}/api/tiers/activites/${id}/length`, {
        method: "GET",
        withCredentials: true,
    }).catch((error) => {
        console.error(`[API ERROR] : Fetching tiers ${id} activities length ${error}`);
        return {status: 400, data: "Erreur lors de la récupération des activités du tiers."};
    })
    if (!resApi || resApi.status !== 200) {
      return {status: 400, data: "Erreur lors de la communication vers l'api."};
    }
    const resData = await resApi.data
    return resData
  } catch (error) {
    return {status: 400, data: "Erreur lors de la communication vers l'api."};
  }
}


export const createTiersActivitie = async({id, data = {}}) => {
  try {
    const resApi = await axios(`${apiBaseUrl}/api/tiers/activites/${id}/create`, {
        method: "POST",
        withCredentials: true,
        data
    }).catch((error) => {
        console.error(`[API ERROR] : Creating activites for tiers ${id} ${error}`);
        return {status: 400, data: "Erreur lors de la création de l'activité."};
    })
    if (!resApi || resApi.status !== 200) {
      return {status: 400, data: "Erreur lors de la communication vers l'api."};
    }
    const resData = await resApi.data
    return resData
  } catch (error) {
    return {status: 400, data: "Erreur lors de la communication vers l'api."};
  }
}


// Contacts
export const fetchTiersContacts = async({id}) => {
  try {
    const resApi = await axios(`${apiBaseUrl}/api/tiers/contacts/${id}`, {
        method: "GET",
        withCredentials: true,
    }).catch((error) => {
        console.error(`[API ERROR] : Fetching tiers ${id} contacts ${error}`);
        return {status: 400, data: "Erreur lors de la récupération des contacts du tiers."};
    })
    if (!resApi || resApi.status !== 200) {
      return {status: 400, data: "Erreur lors de la communication vers l'api."};
    }
    const resData = await resApi.data
    return resData
  } catch (error) {
    return {status: 400, data: "Erreur lors de la communication vers l'api."};
  }
}


export const createTiersContact = async({id, data={}}) => {
  try {
    const resApi = await axios(`${apiBaseUrl}/api/tiers/contacts/${id}/create`, {
        method: "POST",
        withCredentials: true,
        data
    }).catch((error) => {
        console.error(`[API ERROR] : Creating contacts for tiers ${id} ${error}`);
        return {status: 400, data: "Erreur lors de la création du contact."};
    })
    if (!resApi || resApi.status !== 200) {
      return {status: 400, data: "Erreur lors de la communication vers l'api."};
    }
    const resData = await resApi.data
    return resData
  } catch (error) {
    return {status: 400, data: "Erreur lors de la communication vers l'api."};
  }
}

export const deleteTiersContact = async({id, idContact}) => {
  try {
    const resApi = await axios(`${apiBaseUrl}/api/tiers/contacts/${id}/delete/${idContact}`, {
        method: "POST",
        withCredentials: true,
    }).catch((error) => {
        console.error(`[API ERROR] : Deleting contrat for tiers ${id} ${error}`);
        return {status: 400, data: "Erreur lors de la suppression du contact."};
    })
    if (!resApi || resApi.status !== 200) {
      return {status: 400, data: "Erreur lors de la communication vers l'api."};
    }
    const resData = await resApi.data
    return resData
  } catch (error) {
    return {status: 400, data: "Erreur lors de la communication vers l'api."};
  }
}


export const editTiersContact = async({id, idContact, data={}}) => {
  try {
    const resApi = await axios(`${apiBaseUrl}/api/tiers/contacts/${id}/edit/${idContact}`, {
        method: "POST",
        withCredentials: true,
        data
    }).catch((error) => {
        console.error(`[API ERROR] : Editing contact for tiers ${id} ${error}`);
        return {status: 400, data: "Erreur lors de la modification du contact."};
    })
    if (!resApi || resApi.status !== 200) {
      return {status: 400, data: "Erreur lors de la communication vers l'api."};
    }
    const resData = await resApi.data
    return resData
  } catch (error) {
    return {status: 400, data: "Erreur lors de la communication vers l'api."};
  }
}




// Contrats concurrents
// Activites
export const fetchTiersContratsConcurrents = async({id}) => {
  try {
    const resApi = await axios(`${apiBaseUrl}/api/tiers/contrats-concurrents/${id}`, {
        method: "GET",
        withCredentials: true,
    }).catch((error) => {
        console.error(`[API ERROR] : Fetching tiers ${id} activities ${error}`);
        return {status: 400, data: "Erreur lors de la récupération des contrats concurrents du tiers."};
    })
    if (!resApi || resApi.status !== 200) {
      return {status: 400, data: "Erreur lors de la communication vers l'api."};
    }
    const resData = await resApi.data
    return resData
  } catch (error) {
    return {status: 400, data: "Erreur lors de la communication vers l'api."};
  }
}


export const fetchTiersContratConcurrent = async({id, idContrat}) => {
  try {
    const resApi = await axios(`${apiBaseUrl}/api/tiers/contrats-concurrents/${id}/infos/${idContrat}`, {
        method: "GET",
        withCredentials: true,
    }).catch((error) => {
        console.error(`[API ERROR] : Fetching tiers ${id} contract ${idContrat} ${error}`);
        return {status: 400, data: "Erreur lors de la récupération du contrat concurrent."};
    })
    if (!resApi || resApi.status !== 200) {
      return {status: 400, data: "Erreur lors de la communication vers l'api."};
    }
    const resData = await resApi.data
    return resData
  } catch (error) {
    return {status: 400, data: "Erreur lors de la communication vers l'api."};
  }
}

export const createTiersContratConcurrent = async({id, data={}}) => {
  try {
    const resApi = await axios(`${apiBaseUrl}/api/tiers/contrats-concurrents/${id}/create`, {
        method: "POST",
        withCredentials: true,
        data
    }).catch((error) => {
        console.error(`[API ERROR] : Creating contrat for tiers ${id} ${error}`);
        return {status: 400, data: "Erreur lors de la création du contrat."};
    })
    if (!resApi || resApi.status !== 200) {
      return {status: 400, data: "Erreur lors de la communication vers l'api."};
    }
    const resData = await resApi.data
    return resData
  } catch (error) {
    return {status: 400, data: "Erreur lors de la communication vers l'api."};
  }
}

export const editTiersContratConcurrent = async({id, idContrat, data={}}) => {
  try {
    const resApi = await axios(`${apiBaseUrl}/api/tiers/contrats-concurrents/${id}/edit/${idContrat}`, {
        method: "POST",
        withCredentials: true,
        data
    }).catch((error) => {
        console.error(`[API ERROR] : Editing contrat for tiers ${id} ${error}`);
        return {status: 400, data: "Erreur lors de la modification du contrat."};
    })
    if (!resApi || resApi.status !== 200) {
      return {status: 400, data: "Erreur lors de la communication vers l'api."};
    }
    const resData = await resApi.data
    return resData
  } catch (error) {
    return {status: 400, data: "Erreur lors de la communication vers l'api."};
  }
}

export const deleteTiersContratConcurrent = async({id, idContrat}) => {
  try {
    const resApi = await axios(`${apiBaseUrl}/api/tiers/contrats-concurrents/${id}/delete/${idContrat}`, {
        method: "POST",
        withCredentials: true,
    }).catch((error) => {
        console.error(`[API ERROR] : Deleting contrat for tiers ${id} ${error}`);
        return {status: 400, data: "Erreur lors de la suppression du contrat."};
    })
    if (!resApi || resApi.status !== 200) {
      return {status: 400, data: "Erreur lors de la communication vers l'api."};
    }
    const resData = await resApi.data
    return resData
  } catch (error) {
    return {status: 400, data: "Erreur lors de la communication vers l'api."};
  }
}


export const fetchTiersContratConcurrentMateriel = async({id, idContrat}) => {
  try {
    const resApi = await axios(`${apiBaseUrl}/api/tiers/contrats-concurrents/${id}/materiel/${idContrat}`, {
        method: "GET",
        withCredentials: true,
    }).catch((error) => {
        console.error(`[API ERROR] : Fetching tiers ${id} concurrent materials ${idContrat} ${error}`);
        return {status: 400, data: "Erreur lors de la récupération du matériel concurrent du tiers."};
    })
    if (!resApi || resApi.status !== 200) {
      return {status: 400, data: "Erreur lors de la communication vers l'api."};
    }
    const resData = await resApi.data
    return resData
  } catch (error) {
    return {status: 400, data: "Erreur lors de la communication vers l'api."};
  }
}

// Mat

// export const fetchTiersContratConcurrentMaterielInfos = async({id,idContrat,idMateriel}) => {
//   try {
//     const resApi = await axios(`${apiBaseUrl}/api/tiers/contrats-concurrents/materiel/${id}/${idContrat}/${idMateriel}`, {
//         method: "POST",
//         withCredentials: true,
//     }).catch((error) => {
//         console.error(`[API ERROR] : Fetching materiel conc for contrat ${idContrat} ${error}`);
//         return {status: 400, data: "Erreur lors de la récupération du matériel."};
//     })
//     if (!resApi || resApi.status !== 200) {
//       return {status: 400, data: "Erreur lors de la communication vers l'api."};
//     }
//     const resData = await resApi.data
//     return resData
//   } catch (error) {
//     return {status: 400, data: "Erreur lors de la communication vers l'api."};
//   }
// }

export const createTiersContratConcurrentMateriel = async({id,idContrat,data={}}) => {
  try {
    const resApi = await axios(`${apiBaseUrl}/api/tiers/contrats-concurrents/materiel/${id}/${idContrat}/create`, {
        method: "POST",
        withCredentials: true,
        data
    }).catch((error) => {
        console.error(`[API ERROR] : Creating materiel conc for contrat ${idContrat} ${error}`);
        return {status: 400, data: "Erreur lors de la création du matériel."};
    })
    if (!resApi || resApi.status !== 200) {
      return {status: 400, data: "Erreur lors de la communication vers l'api."};
    }
    const resData = await resApi.data
    return resData
  } catch (error) {
    return {status: 400, data: "Erreur lors de la communication vers l'api."};
  }
}


export const editTiersContratConcurrentMateriel = async({id,idContrat,idMateriel,data={}}) => {
  try {
    const resApi = await axios(`${apiBaseUrl}/api/tiers/contrats-concurrents/materiel/${id}/${idContrat}/edit/${idMateriel}`, {
        method: "POST",
        withCredentials: true,
        data
    }).catch((error) => {
        console.error(`[API ERROR] : Editing materiel conc for contrat ${idContrat} ${error}`);
        return {status: 400, data: "Erreur lors de la modification du matériel."};
    })
    if (!resApi || resApi.status !== 200) {
      return {status: 400, data: "Erreur lors de la communication vers l'api."};
    }
    const resData = await resApi.data
    return resData
  } catch (error) {
    return {status: 400, data: "Erreur lors de la communication vers l'api."};
  }
}


export const deleteTiersContratConcurrentMateriel = async({id,idContrat,idMateriel}) => {
  try {
    const resApi = await axios(`${apiBaseUrl}/api/tiers/contrats-concurrents/materiel/${id}/${idContrat}/delete/${idMateriel}`, {
        method: "POST",
        withCredentials: true,
    }).catch((error) => {
        console.error(`[API ERROR] : Deleting materiel conc for contrat ${idContrat} ${error}`);
        return {status: 400, data: "Erreur lors de la suppression du matériel."};
    })
    if (!resApi || resApi.status !== 200) {
      return {status: 400, data: "Erreur lors de la communication vers l'api."};
    }
    const resData = await resApi.data
    return resData
  } catch (error) {
    return {status: 400, data: "Erreur lors de la communication vers l'api."};
  }
}




// Auth

export const loginUser = async({id, psw}) => {
  try {
    const res = await axios(`${apiBaseUrl}/auth/login`, {
        method: "POST",
        withCredentials: true,
        data: {id, psw}
    })
    return res.data
        
    } catch(err) {
        return {
            stauts: 400,
            err: err
        }
    }
}



export const getUserPhoto = async() => {
  try {
    const res = await axios(`${apiBaseUrl}/auth/user/photo`, {
        method: "GET",
        withCredentials: true,
    })
    return res.data
        
    } catch(err) {
        return {
            stauts: 400,
            err: err
        }
    }
}



export const fetchCurrentUser = async() => {
  try {
    const res = await axios(`${apiBaseUrl}/auth/user`, { method: "GET", withCredentials: true })
    return res.data
        
    } catch(err) {
        return {
            stauts: 400,
            err: err
        }
    }
}


export const fetchUserTeams = async() => {
  try {
    const res = await axios(`${apiBaseUrl}/api/teams/me`, { method: "GET", withCredentials: true })
    return res.data
        
    } catch(err) {
        return {
            stauts: 400,
            err: err
        }
    }
}

export const getDataCodesNAF = async() => {
  try {
    const res = await axios(`${apiBaseUrl}/api/data/codeNAF`, { method: "GET", withCredentials: true })
    return res.data.data
    } catch(err) {
        return null
    }
}
export const getDataCommunes = async() => {
  try {
    const res = await axios(`${apiBaseUrl}/api/data/communes`, { method: "GET", withCredentials: true })
    return res.data.data
    } catch(err) {
        return null
    }
}
export const getDataSecteurs = async() => {
  try {
    const res = await axios(`${apiBaseUrl}/api/data/secteurs`, { method: "GET", withCredentials: true })
    return res.data.data
    } catch(err) {
        return null
    }
}

export const getDataAttributions = async({secteur} = {}) => {
  try {
    const res = await axios(secteur ? `${apiBaseUrl}/api/data/attributions/${secteur}` : `${apiBaseUrl}/api/data/attributions`, { method: "GET", withCredentials: true })
    return res.data.data
    } catch(err) {
        return null
    }
}

export const getPersonnel = async({id} = {}) => {
  try {
    const res = await axios(id ? `${apiBaseUrl}/api/data/personnel/${id}` : `${apiBaseUrl}/api/data/personnel`, { method: "GET", withCredentials: true })
    return res.data.data
    } catch(err) {
        return null
    }
}

export const getSiretData = async({siret=""}) => {
  if(!siret) return;
  try {
    const res = await axios(`${apiBaseUrl}/api/data/siret/${siret}`, { method: "GET", withCredentials: true })
    return res.data.data
    } catch(err) {
        return null
    }
}

export const siretExists = async({siret="", currentIdTiers}) => {
  if(!siret) return;
  try {
    const res = await axios(`${apiBaseUrl}/api/tiers/siret/${siret}/exist?currentIdTiers=${currentIdTiers}`, { method: "GET", withCredentials: true })
    return res.data
    } catch(err) {
        return null
    }
}

export const getActiviteActions = async() => {
  try {
    const res = await axios(`${apiBaseUrl}/api/data/activite/actions`, { method: "GET", withCredentials: true })
    return res.data.data
    } catch(err) {
        return null
    }
}


export const getActivitePriorites = async() => {
  try {
    const res = await axios(`${apiBaseUrl}/api/data/activite/priorites`, { method: "GET", withCredentials: true })
    return res.data.data
    } catch(err) {
        return null
    }
}


export const fetchAdresse = async({query}) => {
  try {
    const resApi = await axios(`${apiBaseUrl}/api/data/adresse?q=${query}`, {
        method: "GET",
        withCredentials: true,
    }).catch((error) => {
        console.error(`[API ERROR] : Fetching adresse for ${query} ${error}`);
        return {status: 400, data: "Erreur lors de la récupération des adresses."};
    })
    if (!resApi || resApi.status !== 200) {
      return {status: 400, data: "Erreur lors de la communication vers l'api."};
    }
    const resData = await resApi.data
    return resData
  } catch (error) {
    return {status: 400, data: "Erreur lors de la communication vers l'api."};
  }
}

export const fetchTiersAround  = async({id}) => {
  try {
    const resApi = await axios(`${apiBaseUrl}/api/tiers/${id}/around`, {
        method: "GET",
        withCredentials: true,
    }).catch((error) => {
        console.error(`[API ERROR] : Fetching tiers ${id} around ${error}`);
        return {status: 400, data: "Erreur lors de la récupération des tiers alentours"};
    })
    if (!resApi || resApi.status !== 200) {
      return {status: 400, data: "Erreur lors de la communication vers l'api."};
    }
    const resData = await resApi.data.data
    return resData
  } catch (error) {
    return {status: 400, data: "Erreur lors de la communication vers l'api."};
  }
}


export const parseAdresse = (adresse = "") => {
  // Utilisation d'une expression régulière pour extraire l'adresse, le code postal et la ville
  const regex = /(.+)\s+(\d{5})\s+(.+)/;
  const match = adresse.match(regex);

  if (match) {
      const adresseDebut = match[1].trim(); // L'adresse avant le code postal
      const codePostal = match[2];
      const ville = match[3].trim();
      return {
          adresseDebut: adresseDebut,
          codePostal: codePostal,
          ville: ville
      };
  } else {
      return null;
  }
}


export const createSecteurAdresse = async({ville = ""}) => {
  try {
    const resApi = await axios(`${apiBaseUrl}/api/data/communes/create`, {
        method: "POST",
        withCredentials: true,
        headers: {'Content-Type': 'application/json'},
        data: {
          ville: ville
        }
    }).catch((error) => {
        console.error(`[API ERROR] : Creating secteur for ${ville} ${error}`);
        return {status: 400, data: "Erreur lors de la création du secteur"};
    })
    if (!resApi || resApi.status !== 200) {
      return {status: 400, data: "Erreur lors de la communication vers l'api."};
    }
    const resData = await resApi.data
    return resData
  } catch (error) {
    return {status: 400, data: "Erreur lors de la communication vers l'api."};
  }
}




export const authLogout  = async() => {
  try {
    const resApi = await axios(`${apiBaseUrl}/auth/logout`, {
        method: "GET",
        withCredentials: true,
    }).catch((error) => {
        console.error(`[API ERROR] : Logout ${error}`);
        return null
    })
    return null
  } catch (error) {
    return null
  }
}



// Admin

export const fetchUsers = async({query = ""}) => {
  try {
    const resApi = await axios(`${apiBaseUrl}/api/admin/users?q=${query}`, {
        method: "GET",
        withCredentials: true,
    }).catch((error) => {
        console.error(`[API ERROR] : Fetching users`);
        return {status: 400, data: "Erreur lors de la récupération des utilisateurs."};
    })
    if (!resApi || resApi.status !== 200) {
      return {status: 400, data: "Erreur lors de la communication vers l'api."};
    }
    const resData = await resApi.data
    return resData
  } catch (error) {
    return {status: 400, data: "Erreur lors de la communication vers l'api."};
  }
}

 // Fonction qui retourne une promesse pour charger l'image
 export const loadImage = (src) => {
  return new Promise((resolve, reject) => {
      const image = new Image();
      image.src = src;

      // Quand l'image est chargée, on résout la promesse
      image.onload = () => resolve(image);
      image.onerror = (err) => reject(err); // Gère l'erreur en cas d'échec du chargement
  });
};



// Stats
export const fetchActivitesPointsByWeek = async() => {
  try {
    const resApi = await axios(`${apiBaseUrl}/api/stats/activites/points`, {
        method: "GET",
        withCredentials: true,
    }).catch((error) => {
        console.error(`[API ERROR] : Fetching points act by week`);
        return {status: 400, data: "Erreur lors de la récupération des points."};
    })
    if (!resApi || resApi.status !== 200) {
      return {status: 400, data: "Erreur lors de la communication vers l'api."};
    }
    const resData = await resApi.data
    return resData
  } catch (error) {
    return {status: 400, data: "Erreur lors de la communication vers l'api."};
  }
}





// Stats
export const fetchActivitesPointsByWeekTeams = async() => {
  try {
    const resApi = await axios(`${apiBaseUrl}/api/stats/activites/points/teams`, {
        method: "GET",
        withCredentials: true,
    }).catch((error) => {
        console.error(`[API ERROR] : Fetching points act by week`);
        return {status: 400, data: "Erreur lors de la récupération des points de l'équipe."};
    })
    if (!resApi || resApi.status !== 200) {
      return {status: 400, data: "Erreur lors de la communication vers l'api."};
    }
    const resData = await resApi.data
    return resData
  } catch (error) {
    return {status: 400, data: "Erreur lors de la communication vers l'api."};
  }
}

export const api = apiBaseUrl


