// DataContext.js
import React, { createContext, useState, useEffect, useContext, useCallback, useMemo } from 'react';
import { authLogout, fetchCurrentUser, getUserPhoto } from '../atna';

// Créez le contexte
const DataContext = createContext();

// Fournisseur de contexte
const AuthProvider = ({ children }) => {
    const [user, setUser] = useState({ isAuth: false });
    const [loading, setLoading] = useState(true);
    const [userPhoto, setUserPhoto] = useState("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'/%3E");

    const fetchUser = useCallback(async () => {
        try {
            const userRes = await fetchCurrentUser();
            if (userRes.status === 200) {
                setUser({ isAuth: true, ...userRes.data });
            } else {
                setUser({ isAuth: false });
            }
        } catch (error) {
            console.error("Error fetching user:", error);
            setUser({ isAuth: false });
        } finally {
            setLoading(false);
        }
    }, []);

    const fetchProfilePicture = useCallback(async () => {
        try {
            const resApi = await getUserPhoto();
            if (resApi.status === 200 && resApi.data) {
                setUserPhoto(resApi.data);
            }
        } catch (error) {
            console.error("Error fetching profile picture:", error);
        }
    }, []);

    const handleLogout = useCallback(async () => {
        try {
            await authLogout();
            setUser({ isAuth: false });
        } catch (error) {
            console.error("Error during logout:", error);
        }
    }, []);

    useEffect(() => {
        fetchUser();
    }, [fetchUser]);

    useEffect(() => {
        if (user.isAuth) {
            fetchProfilePicture();
        }
    }, [user.isAuth, fetchProfilePicture]);

    const value = useMemo(() => ({
        user,
        setUser,
        loading,
        setLoading,
        fetchUser,
        userPhoto,
        setUserPhoto,
        handleLogout
    }), [user, loading, userPhoto, fetchUser, handleLogout]);

    return (
        <DataContext.Provider value={value}>
            {children}
        </DataContext.Provider>
    );
};

// Custom hook for using the data context
const useAuth = () => {
    const context = useContext(DataContext);
    if (context === undefined) {
        throw new Error("useAuth must be used within an AuthProvider");
    }
    return context;
};

export { AuthProvider, useAuth };
