import React, { useEffect, useRef, useState } from 'react';
import {  TiArrowRight, TiVendorMicrosoft } from "react-icons/ti";
import { HiOutlineArrowRight } from "react-icons/hi";
import {BiLoaderAlt} from 'react-icons/bi'
import config from '../../../utils/config';
import TextInput from '../components/textInput'
import { cn } from '../../../utils/cn';
import { loginUser } from '../../../utils/atna';
import { api } from '../../../utils/atna'
import { useSocket } from '../../../utils/hooks/useSocket';

const Login = ({fetchUser}) => {

    const [data, setData] = useState({id: "",psw: ""})
    const [loading, setLoading] = useState(false)
    const {clientId, socket} = useSocket()

    function handleLogin() {
        var left = (window.screen.width/2)-(500/2);
        var top = (window.screen.height/2)-(750/2);
        const authWindow = window.open((api)+`/auth/azureadoauth2?clientId=${clientId}`, 'aTnaLogin', `tabbar=no, toolbar=no, location=no, directories=no, status=no, menubar=no, titlebar=no, scrollbars=no, zoom=80 ,resizable=no, width=500, height=700, left=${left}, top=${top}`)
        socket.on('loginClosed', () => {
           // console.log('login closed')
                fetchUser();
            setTimeout(() => {
                fetchUser();
            }, 100)
            setTimeout(() => {
                fetchUser();
            }, 150)
            setTimeout(() => {
                fetchUser();
            }, 200)
        })
      }

      async function handleLocalLogin() {
        setLoading(true)
        const loginRes = await loginUser(data)
        if(loginRes.status !== 200) {
            setLoading(false);
            if(!loginRes.data) return;
           if(loginRes.data?.toLowerCase().includes('identifiant')) document.querySelector('.idInp')?.focus()
           else if(loginRes.data?.toLowerCase().includes('passe')) document.querySelector('.pswInp')?.focus()
           window.alert(loginRes.data)
        }
        fetchUser()
        setLoading(false);
      }


    return (
        <div className='w-full h-full flex items-center max-sm:flex-col'>

      

      
        <div className="w-full max-w-[700px] max-sm:max-w-full h-full flex px-12 py-12 max-sm:px-6 bg-white backdrop-blur-sm flex-col justify-between items-end  gap-y-2 max-sm:pb-10 max-sm:h-2/3 max-sm:py-6">
             <div className="h-max w-max">
                {/* <img src={require('../../Cdn/Images/itandyou.png')} alt="IT & You" className='absolute right-12 bottom-12 h-14 max-sm:hidden' /> */}
                 {/* <div className="w-max"><img src={require('../../Cdn/Images/atna_logo_n.png')} alt="aTna" className='h-16 max-sm:hidden' /></div>
                 <div className="w-max"><img src={require('../../Cdn/Images/atna_icone_n.png')} alt="aTna" className='h-12 hidden max-sm:block' /></div> */}
             </div>

                    <div 
                    onKeyDown={(e) => {
                        if(e.key === "Enter") {
                            if(!(data.id && data.psw)) return;
                            handleLocalLogin()
                        }
                    }}
                    className="flex flex-col justify-center items-end max-sm:w-full max-sm:items-end gap-y-2">
                        <h1 className='text-3xl  text-grey font-sans font-bold'>Connexion à aTna</h1>
                        <div className="flex flex-col gap-y-1 items-end">

                        <div className="flex flex-col gap-y-0 items-end">
                            <h1 className='text-base'>Identifiant :</h1>
                            <TextInput object={data} setObject={setData} realTime={true} objValue={"id"} className={'w-[300px] min-h-[30px]'} inpClassName={'idInp'}/>
                        </div>
                            
                        <div className="flex flex-col gap-y-0 items-end">
                            <h1 className='text-base'>Mot de passe :</h1>
                            <TextInput object={data} setObject={setData} realTime={true} objValue={"psw"} className={'w-[300px] min-h-[30px]'} inpClassName={'pswInp'}/>
                        </div>

                        <div 
                        onClick={() => {
                            if(!(data.id && data.psw)) return;
                            handleLocalLogin()
                        }}
                        className={cn("bg-grey mt-2 px-10 py-2 h-[35px] text-sm w-max text-wh flex items-center gap-x-4 select-none cursor-pointer rounded-2xl font-medium transition-opacity duration-75", !(data.id && data.psw) && 'pointer-events-none opacity-95')}>
                           {loading ? <BiLoaderAlt className='animate-spin'/> : <HiOutlineArrowRight/>}
                        </div>

                        <div className="flex w-[300px] items-center gap-x-4 my-4">
                            <div className="flex flex-1 h-[1.5px] bg-grey/10"></div>
                            <h1 className='text-grey/10 text-sm font-bold uppercase'>Ou Avec</h1>
                        </div>



                        </div>
                        <div 
                        onClick={() => {
                            handleLogin()
                        }}
                        className="bg-grey px-10 py-2 h-[35px] text-sm font-base w-max text-wh flex items-center gap-x-4 select-none cursor-pointer rounded-2xl font-medium">
                            <TiVendorMicrosoft/> Microsoft
                        </div>
                    </div>


                    <div className="flex h-16 max-sm:hidden">
                    </div>
            </div>

            
            <div className="flex-1 h-full  max-sm:h-1/3 max-sm:w-full relative"
             style={{
                background: "url(/cdn/images/loginBack.png)",
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                filter: 'brightness(70%)'
            }}
            >
            </div>
      

        </div>
    );
}

export default Login;