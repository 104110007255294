import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { fetchUsers } from '../../../../utils/atna';
import { useAlerts } from '../../../../utils/hooks/useAlert';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { debounce } from 'lodash';
import { IoEllipse, IoEllipsisVertical } from 'react-icons/io5';

const AdminUsersView = () => {
    const navigate = useNavigate();
    const [allUsers, setAllUsers] = useState([]);
    const { addAlert } = useAlerts();
    const [searchParams, setSearchParams] = useSearchParams();
    const [searchTerm, setSearchTerm] = useState(searchParams.get('q') || "");

    const fetchAllUsers = useCallback(async (term) => {
        const resApi = await fetchUsers({ query: term });
        if (resApi.status === 200) {
            setAllUsers(resApi.data);
        } else {
            addAlert(resApi.data);
        }
    }, [addAlert]);

    // Requête initiale au montage du composant, si searchTerm est vide
    useEffect(() => {
        if (!searchTerm) {
            fetchAllUsers("");
        }
    }, [fetchAllUsers, searchTerm]);

    // Déclenchement du debounce sur la recherche
    const debouncedFetch = useMemo(() => debounce((term) => {
        fetchAllUsers(term);
    }, 200), [fetchAllUsers]);

    // Gère les changements dans la barre de recherche
    const handleSearchChange = (e) => {
        const value = e.target.value;
        setSearchParams({ q: value });
        setSearchTerm(value);
        debouncedFetch(value);
    };

    return (
        <div className='w-full h-full flex flex-col items-center'>
            <div className="atna-search-bar flex items-center w-[90%] max-w-[500px] rounded-[5px] bg-[rgba(0,0,0,0.5)] h-[26px] shrink-0 grow-0">
                <input
                    defaultValue={searchParams.get('q') || ""}
                    type="text"
                    className='h-full w-full border-none outline-none bg-transparent px-[20px] text-[12px] placeholder:text-[rgba(0,0,0,0.5)] placeholder:text-[11px] font-normal py-[5px] tracking-wide'
                    placeholder='Rechercher par nom, prénom'
                    onChange={handleSearchChange}
                />
            </div>

            <div className="flex w-full mt-[50px]">
                {allUsers.length > 0 ? (
                    <div className='w-full h-full flex justify-center mb-[50px]'>
                        <div className="atna-search-inner">
                            <table className='atna-search-table'>
                                <thead>
                                    <tr className='atna-hd-table'>
                                        <th>Nom</th>
                                        <th>Prénom</th>
                                        <th>Tel. Perso</th>
                                        <th>Tel. Pro</th>
                                        <th>Mail</th>
                                        <th>Id</th>
                                        <th className='w-[20px] min-w-0 border-none'></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {allUsers.slice(0, 20).map((userMp, userMpInd) => (
                                        <tr key={"tbA_" + userMpInd} className='cursor-pointer'
                                            onClick={() => navigate(`/admin/users/${userMp.id}`)}
                                            role='button' tabIndex={0}>
                                            <th>{userMp.nom}</th>
                                            <th>{userMp.prenom}</th>
                                            <th>{userMp.telPerso}</th>
                                            <th>{userMp.telPro}</th>
                                            <th>{userMp.mailPro}</th>
                                            <th>{userMp.id}</th>
                                            <th className='min-w-0 flex items-center border-none'>
                                                <div className='relative px-[20px]' onClick={(e) => {
                                                    e.stopPropagation()
                                                }}>
                                                    <IoEllipsisVertical/>
                                                </div>
                                            </th>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                ) : (
                    <div className='w-full flex justify-center text-[13px] text-[rgb(133,132,128)] font-normal mt-[50px]'>
                        Aucun résultat.
                    </div>
                )}
            </div>
        </div>
    );
}

export default AdminUsersView;
