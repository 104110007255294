import { useCallback, useEffect, useState } from "react";
import { cn } from "../../../utils/cn";

const TextInput = ({ className, inpClassName, object, setObject, objValue, validation, maxLength, title, realTime }) => {
    const [state, setState] = useState({
      value: "",
      isWrong: false,
      errMsg: ""
    });

    useEffect(() => {
      if(object[objValue] !== undefined) setState((prev) => {
        return {
          ...prev,
          value: object[objValue]
        }
      })
  }, [object, objValue])
  
    const updateObject = useCallback((newVl) => {
      if(!(objValue in object)) return;
      let newValue = newVl.trimStart().slice(0, maxLength);
      if (validation) {
        const resValidation = validation(newValue);
        if (resValidation.err) {
          setState((prevState) => ({
            ...prevState,
            isWrong: true,
            errMsg: resValidation.message
          }));
          return;
        } else {
          setState((prevState) => ({
            ...prevState,
            value: newValue,
            isWrong: false,
            errMsg: ""
          }));
        }
      }
      setObject((prev) => {
        if (prev[objValue] !== newValue) {
          prev[objValue] = newValue;
        }
        return { ...prev };
      });
    }, [validation, maxLength, objValue, setObject, object]);
  
    return (
      <div
        key={objValue}
        title={title}
        className={cn(
          'group relative flex flex-1 h-[30px] max-h-[30px] ring-1 ring-grey/50 rounded-[10px] focus-within:ring-grey focus-within:ring-2 transition-all duration-100',
          className,
          state.isWrong && 'ring-2 ring-error focus-within:ring-2 focus-within:ring-error'
        )}
      >
        <input
          value={state.value || ""}
          maxLength={maxLength}
          onChange={(e) => {
            setState((prevState) => ({
              ...prevState,
              value: e.target.value.trimStart().slice(0, maxLength)
            }));

            if(realTime) {
            updateObject(e.target.value);
            }
          }}
          onBlur={(e) => {
            updateObject(e.target.value);
          }}
          className={cn(
            'outline-none border-none flex flex-1 px-4 bg-transparent text-sm cursor-pointer focus:cursor-text font-display',
            inpClassName
          )}
          type="text"
        />
        <div
          className={cn(
            "absolute w-full min-h-[30px]  bg-error flex items-center text-baseline px-4  py-1 rounded-[10px] top-[calc(100%+10px)] z-50 pointer-events-none text-sm text-wh opacity-0 transition-opacity duration-100",
            state.isWrong && 'opacity-100 pointer-events-auto'
          )}
        >
          {state.errMsg}
        </div>
      </div>
    );
  }
  
  
  export default TextInput;